@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;600&display=swap");

.careersBigText {
  color: white;
  width: 100%;
  max-width: 721px;
  padding-left: 50px;
  font-family: Calibri;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 59px;
  letter-spacing: 0.800000011920929px;
  text-align: left;
  margin-bottom: 1rem;
}
.careersSmallText {
  color: white;
  padding-left: 50px;
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.6000000238418579px;
  text-align: left;
}
.careersSmallestTest {
  color: white;
  padding-left: 50px;
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}
/* Culture Core Value styling... */
.coreValueBk {
  width: 100%;
  height: 490px;
  background-color: #e6f7ff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.corevaluetext {
  font-size: large;
  font-size: 28px;
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #131747;
}
.corevaluetext span {
  font-weight: bold;
  color: #131747;
}
.corestatement {
  margin-top: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
  color: #6e6e6e;
}
/*JOIN OUR TEAM COMPONENT STYLES*/
.jointeam {
  background-color: white;
  width: 100%;
}
.wrapperall {
  background-color: white;
}
.jointeam h1 {
  margin-bottom: 0;
  margin-top: 0;
}
.jointeam p {
  margin-top: 0;
  margin-bottom: 0;
}
.jointeamcontainer {
  text-align: center;
  padding-top: 76px;
  margin-bottom: 2.5rem;
}
.flexcardcontainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 4rem;
}
.cardourteam {
  height: 263px;
  width: 307px;
  left: 179px;
  margin-bottom: 1rem;
  top: 1807px;
  border-radius: 8px;
  border: 1.5px solid #c2ebfe;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.boldpartoftext {
  font-weight: bold;
}
.textdifferent {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: #e06c26;
  margin-bottom: 0;
}
.achievers {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: 29px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  color: #6e6e6e;
}
.joinourteam {
  font-size: large;
  font-size: 28px;
  margin-bottom: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #131747;
}
.joinourteam span {
  font-weight: bold;
}
.availablebtn {
  height: 50px;
  width: 262px;
  left: 589px;
  top: 2484px;
  border-radius: 25px;
  background: linear-gradient(180deg, #43ddf7 0%, #20b8eb 100%);
  border: none;
  font-size: bold;
  color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;

  cursor: pointer;
}
.availablebtn:hover {
  transform: translateY(-3px);
  background-color: transparent;
}
.availablebtn:active {
  transform: translateY(-1px);
  border: 1px solid red;
  color: red;
}
/*CANARY COLONY COMPONENT STYLES*/
.colonycontainer {
  width: 100%;
  height: 576px;
  max-width: 1280px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  flex-wrap: wrap;
}
.bkgcolony {
  background-color: white;
}
.flexcolonyitem1 {
  width: 50%;
}
.flexcolonyitem {
  width: 50%;
}
.avatar {
  vertical-align: middle;
  width: 50%;
  height: 50%px;
  border-radius: 50%;
}
.avatar2 {
  vertical-align: middle;
  width: 50%;
  height: auto;
  border-radius: 50%;
  border: 10px solid white;
  position: relative;
  top: -6rem;
  left: 8.2rem;
}
.colonytext {
  width: 450px;
  height: 116px;
  text-justify: inter-word;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 1rem;
  color: #6e6e6e;
}
.joinusbtn {
  height: 50px;
  width: 199px;
  left: 748px;
  top: 930px;
  border-radius: 25px;
  background: linear-gradient(180deg, #43ddf7 0%, #20b8eb 100%);
  color: white;
  font-size: bold;
  outline: none;
  border: none;
}

.joinusbtn:hover {
  transform: translateY(-3px);
  background-color: transparent;
}
.joinusbtn:active {
  transform: translateY(-1px);
  border: 1px solid red;
  color: red;
}
/* Carousel Styling Section */
.flexcontainercarousel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1280px;
  width: 100%;
}

.carousel {
  width: 55%;
}
.carouseltextarea {
  width: 45%;
  position: relative;
  height: 429px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgpaddingright {
  padding-right: 2rem;
}
.textareaitems {
  position: absolute;
  transform: translateY(-50%);
}
.carouselbtn {
  background: linear-gradient(180deg, #43ddf7 0%, #20b8eb 100%);
  height: 50px;
  width: 199px;
  left: 133px;
  top: 2959px;
  border-radius: 25px;
  border: none;
  outline: none;
  font-family: Calibri;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
}
.carouselbtn:hover {
  transform: translateY(-3px);
  background-color: transparent;
}
.carouselbtn:active {
  transform: translateY(-1px);
  border: 1px solid red;
  color: red;
}
.carouselinnertext {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  text-align: left;
  color: #6e6e6e;
  height: 58px;
  width: 89%;
  margin-top: 0;
  margin-bottom: 2rem;
}
/* Careers Job Component Styling  */
.backgroundjobbanner {
  background-image: url(../../assets/images/jobs.png);
  height: 478px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.interestedbkground {
  height: 460px;
  background-color: white;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

.interestedinnertext {
  color: #131747;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 28px;
  line-height: 29px;
  letter-spacing: 0.46666666865348816px;
  text-align: center;
  margin-bottom: 1.8rem;
}
.careerjobswitch {
  position: absolute;
  top: 50px;
}
/* QUERIES */
/* Extra Small */
@media screen and (max-width: 576px) {
  .careersBigText {
    font-size: 32px;
  }
  .carouselflexitem {
    display: flex;
    position: relative;
    width: auto;
    flex-direction: column;
  }
  .imgpaddingright {
    padding-right: 0;
  }
  .cardWhiteDesign {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .cardWhiteDesignContainer {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    overflow-x: scroll;
    overflow: -moz-hidden-unscrollable;
  }
  .achievers {
    font-size: 15px;
  }
  .picturesdown {
    height: auto !important ;
    margin-top: 2rem;
  }
}
/* Small  */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .cardWhiteDesign {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .cardWhiteDesignContainer {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: scroll;
    overflow: hidden;
    cursor: pointer;
  }
}
/*Other Screen adjustment For Perfect Layout*/
@media screen and (max-width: 638px) {
  .bkgcolony {
    background-color: white;
    height: 800px;
  }
}
@media screen and (max-width: 441px) {
  .bkgcolony {
    background-color: white;
    height: 760px;
  }
}
@media screen and (max-width: 990px) {
  .flexcolonyitem1 {
    width: 101%;
  }
  .flexcolonyitem {
    width: 101%;
  }
}
@media screen and (max-width: 915px) {
  .colonytext {
    width: 100%;
  }
  .colonytext {
    height: auto;
  }
}
@media screen and (max-width: 563px) {
  .colonycontainer {
    height: 900px !important;
  }
}
@media screen and (max-width: 638px) {
  .colonycontainer {
    height: 638px !important;
  }
}
@media screen and (max-width: 767px) {
  .colonycontainer {
    height: 1049px;
  }
  .picturesdown {
    height: 486px;
    margin-top: 2rem;
  }
  .colonytext {
    height: auto;
  }
}
/* MEDIUM  */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardWhiteDesign {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
  }
  .cardWhiteDesignContainer {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: scroll;
    overflow: hidden;
  }
  .colonycontainer {
    height: 1010px;
  }
  .picturesdown {
    height: 700px;
    margin-top: 2rem;
  }
  .carousel {
    width: 101%;
  }
  .carouseltextarea {
    width: 101%;
    position: relative;
  }
}
/* LARGE SCREEN  */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .cardWhiteDesign {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
  }
  .cardWhiteDesignContainer {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: scroll;
    scroll-behavior: smooth;
    overflow: hidden;
  }
}
@media screen and (min-width: 1200px) {
  .cardWhiteDesign {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
  }
  .cardWhiteDesignContainer {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: scroll;
    overflow: hidden;
  }
  .picturesdown {
    margin-top: 20%;
  }
}
/* Media Queries Padding for Carousel display */
@media screen and (max-width: 992px) {
  .carousel {
    width: 101%;
  }
  .carouseltextarea {
    width: 101%;
    position: relative;
  }
  .carouselinnertext {
    height: 58px;
    width: 100%;
  }
  .carouseltextarea {
    height: 243px;
  }
}
@media screen and (min-width: 577px) {
  .carouselflexitem {
    display: flex;
    position: relative;
    background-color: white;
  }
}
