.aboutBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/modal/header.png);
  height: 478px;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutbannertext {
  max-width: 100%;
  width: 757px;
  margin-left: auto;
  margin-right: auto;
  font-family: Calibri;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.8999999761581421px;
}

.aboutUsSectioncontainer {
  display: flex;
  flex-wrap: wrap;
  height: 640px;
  justify-content: space-between;
  align-items: center;
}

.aboutusitemone {
  width: 60%;

  position: relative;
}
.aboutusitemtwo {
  width: 35%;
}
.itemonetext {
  font-family: Calibri;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #6e6e6e;
}
.aboutbolded {
  font-weight: bold;
  color: #131747;
}
.abouttextcontainersmall {
  color: #131747;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
/* VISION MISSION COMPONENT STYLING */
.missionvisionbk {
  background-color: #e6f7ff;
  height: 421px;
  display: flex;
  align-items: center;
}
.visionmissioncontainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.articleparagraph {
  text-align: center;
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  color: #6e6e6e;
}
.paragraphtextwidth {
  text-align: center;
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  color: #6e6e6e;
  width: 80%;
}

.articleinnertext {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.articlepercentagetwo {
  width: 50%;
}
.articlepercentageone {
  width: 50%;
}
/* OUR PEOPLE COMPONENT STYLING */
.btntogglecontainer {
  display: flex;
}

.ourpeoplesubtext {
  text-align: center;
  font-family: Calibri;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.4000000059604645px;
  color: #6e6e6e;
  margin: 0;
}
.ourpeopletextcontainer {
  padding-top: 5rem;
  padding-bottom: 2.6rem;
}

/* QUERIES */
/* Extra Small */
@media screen and (max-width: 576px) {
  .aboutbannertext {
    font-size: 26px;
  }
}
/* Small  */
@media screen and (min-width: 576px) and (max-width: 767px) {
}
/*Other Screen adjustment For Perfect Layout*/
@media screen and (max-width: 638px) {
}
@media screen and (max-width: 441px) {
}
@media screen and (max-width: 990px) {
}
@media screen and (max-width: 563px) {
}
@media screen and (max-width: 768px) {
  .aboutUsSectioncontainer {
    height: 1000px;
  }
  .aboutusitemone {
    width: 101%;

    position: relative;
  }
  .aboutusitemtwo {
    width: 101%;
  }
  .missionvisionbk {
    height: 600px;
  }
  .articlepercentagetwo {
    width: 101%;
  }
  .articlepercentageone {
    width: 101%;
  }
}
@media screen and (max-width: 915px) {
}

/* MEDIUM  */
@media screen and (min-width: 768px) and (max-width: 991px) {
}
/* LARGE SCREEN  */
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 1200px) {
  .containercolor {
    height: 1100px;
    background-color: white;
  }
}
/* Media Queries Padding for Carousel display */
@media screen and (max-width: 992px) {
}
