.foundationBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/foundationimg/foundationbanner.png);
  height: 478px;
  color: white;
  font-family: "Gotham Rounded";
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.foundationtext {
  max-width: 100%;
  width: 757px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Gotham Rounded";
  font-size: 46px;
  font-style: normal;
  font-weight: bolder;
  line-height: 43px;
  letter-spacing: 0px;
  height: auto;
  /* text-align: center; */
}
.foundationstatement {
  width: 800px;
  max-width: 100%;
  height: 150px;
  background-color: #4f88c7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.foundationcardglobalcontainer {
  margin: 0 auto;
  width: 80%;
  margin-bottom: 4rem;
}
.imgfoundationcontainer {
  width: 40%;
}
.foundationbordercontainer {
  border: 1.5px solid #4f88c7;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
}
.foundationcarddescription {
  color: #131747;
  font-family: Gotham Rounded;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
}
.foundationcarddescriptionbolded {
  font-weight: bold;
}

/* QUERIES */
/* Extra Small */
@media screen and (max-width: 540px) {
  .sidetextoffoundationcard {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: justify !important;
  }
}
@media screen and (max-width: 576px) {
  .foundationstatement {
    width: 800px;
    max-width: 100%;
    height: 250px;
    background-color: #4f88c7;
    position: absolute;
    top: 50%;
    padding: 1rem;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 800px) {
  .foundationcardglobalcontainer {
    margin: 0 auto;
    width: 100%;
  }
  .sidetextoffoundationcard {
    width: 101%;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.3333333432674408px;
    text-align: left;
    color: #6e6e6e;
    padding-top: 1rem;
    text-align: justify !important;
  }
  .imgfoundationcontainer {
    width: 101%;
  }
}
@media screen and (min-width: 801px) {
  .sidetextoffoundationcard {
    width: 60%;
    padding-left: 2rem;
    font-family: Calibri;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.3333333432674408px;
    text-align: left;
    color: #6e6e6e;
    padding-top: 1rem;
  }
}
