.btntogglecontainerfirstactive {
  width: 250px;
  background: white;
  outline: none;
  border-top: 10px solid #e6f7ff !important;
  border-bottom: none;
  border-left: none;
  border-right: none;
  height: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #20b8eb;
  border-radius: 3px;
}
.btntogglecontainerfirstnonactive {
  width: 251px;
  background: white;
  outline: none;
  border: none;
  height: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #c6c6c6;
  border-radius: 3px;
}

.article-width {
  width: 250px;
}
.text-center-our-people {
  text-align: center;
  color: #6e6e6e;
  margin: 0;
}
.our-people-img {
  vertical-align: middle;
  width: 80%;
  height: auto;
  border-radius: 50%;
}
.name-styles-stakeholders {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 5px;
  color: #131747;
}
.name-styles-stakeholders-for-svg {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
  color: #131747;
}
.name-styles-stakeholders-last-four {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
  color: #131747;
}
.name-styles-stakeholders-bolded {
  font-weight: bold;
}

.flex-article-ourpeople {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3.5rem;
  text-align: center;
}

/*MODAL CSS*/
.content-centered-popup {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 2rem;
  padding-bottom: 3rem;
  height: auto;
  border-radius: 50%;
}
.popup-main-text-width {
  width: 80%;
  text-align: left;
  margin-left: auto;
  padding-top: 1.2rem;
  margin-right: auto;
  font-family: Calibri;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.30000001192092896px;
  color: #6e6e6e;
  padding-bottom: 2rem;
}
.popup-title-text {
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  text-align: center;
  color: #20b8eb;
}
.close-btn-position {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 2rem;
}
/* Extra Small */
@media screen and (max-width: 576px) {
  .ourpeoplestakeholdercontainer {
    background: #e6f7ff;
    padding-left: 2.5rem;
    padding-top: 2.5rem;
    padding-right: 2rem;
    padding-bottom: 2.5rem;
    border-radius: 9px;
    display: flex;
    justify-content: space-evenly !important;
    align-items: center;
    margin: 0px auto;
    max-width: 1250px;
    flex-wrap: wrap;
    height: 500px;
    max-width: 1250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .name-styles-stakeholders-last-four {
    padding-top: 1.6rem;
  }
  .btntogglecontainerfirstnonactive {
    width: 150px;
  }
  .btntogglecontainerfirstactive {
    width: 150px;
  }
  #btn-management-margin {
    margin-left: 12px !important;
  }
}
/*LARGE SCREEN  AND BELOW*/
@media screen and (max-width: 577px) {
  #btn-management-margin {
    margin-left: 1.5rem;
  }
}

@media screen and (max-width: 1199px) {
  .ourpeoplestakeholdercontainer {
    background: #e6f7ff;
    padding-left: 2.5rem;
    padding-top: 2.5rem;

    padding-bottom: 2.5rem;
    border-radius: 9px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    height: 500px;
    max-width: 1250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

/*LARGE SCREEN*/
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1200px) {
  .ourpeoplestakeholdercontainer {
    background: #e6f7ff;
    padding-left: 2.5rem;
    padding-top: 2.5rem;
    padding-right: 2rem;
    border-radius: 9px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    max-width: 1250px;
  }
}
