@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;600&display=swap);
.About_aboutBanner__3cA9u {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-image: url(/static/media/header.45f01657.png);
  height: 478px;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.About_aboutbannertext__2GFzO {
  max-width: 100%;
  width: 757px;
  margin-left: auto;
  margin-right: auto;
  font-family: Calibri;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.8999999761581421px;
}

.About_aboutUsSectioncontainer__1CWav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 640px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.About_aboutusitemone__Ph3Jr {
  width: 60%;

  position: relative;
}
.About_aboutusitemtwo__2zf47 {
  width: 35%;
}
.About_itemonetext__3LKIP {
  font-family: Calibri;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #6e6e6e;
}
.About_aboutbolded__2cUP9 {
  font-weight: bold;
  color: #131747;
}
.About_abouttextcontainersmall__gU7ez {
  color: #131747;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
/* VISION MISSION COMPONENT STYLING */
.About_missionvisionbk__2hUx0 {
  background-color: #e6f7ff;
  height: 421px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.About_visionmissioncontainer__3oKnm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.About_articleparagraph__2dWPu {
  text-align: center;
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  color: #6e6e6e;
}
.About_paragraphtextwidth__3UiKx {
  text-align: center;
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  color: #6e6e6e;
  width: 80%;
}

.About_articleinnertext__Ttnh4 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-flow: column;
          flex-flow: column;
}
.About_articlepercentagetwo__1N_G4 {
  width: 50%;
}
.About_articlepercentageone__21URF {
  width: 50%;
}
/* OUR PEOPLE COMPONENT STYLING */
.About_btntogglecontainer__2CCmI {
  display: -webkit-flex;
  display: flex;
}

.About_ourpeoplesubtext__cLEQ1 {
  text-align: center;
  font-family: Calibri;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.4000000059604645px;
  color: #6e6e6e;
  margin: 0;
}
.About_ourpeopletextcontainer__2AWU6 {
  padding-top: 5rem;
  padding-bottom: 2.6rem;
}

/* QUERIES */
/* Extra Small */
@media screen and (max-width: 576px) {
  .About_aboutbannertext__2GFzO {
    font-size: 26px;
  }
}
/* Small  */
@media screen and (min-width: 576px) and (max-width: 767px) {
}
/*Other Screen adjustment For Perfect Layout*/
@media screen and (max-width: 638px) {
}
@media screen and (max-width: 441px) {
}
@media screen and (max-width: 990px) {
}
@media screen and (max-width: 563px) {
}
@media screen and (max-width: 768px) {
  .About_aboutUsSectioncontainer__1CWav {
    height: 1000px;
  }
  .About_aboutusitemone__Ph3Jr {
    width: 101%;

    position: relative;
  }
  .About_aboutusitemtwo__2zf47 {
    width: 101%;
  }
  .About_missionvisionbk__2hUx0 {
    height: 600px;
  }
  .About_articlepercentagetwo__1N_G4 {
    width: 101%;
  }
  .About_articlepercentageone__21URF {
    width: 101%;
  }
}
@media screen and (max-width: 915px) {
}

/* MEDIUM  */
@media screen and (min-width: 768px) and (max-width: 991px) {
}
/* LARGE SCREEN  */
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 1200px) {
  .About_containercolor__3RcaH {
    height: 1100px;
    background-color: white;
  }
}
/* Media Queries Padding for Carousel display */
@media screen and (max-width: 992px) {
}

.btntogglecontainerfirstactive {
  width: 250px;
  background: white;
  outline: none;
  border-top: 10px solid #e6f7ff !important;
  border-bottom: none;
  border-left: none;
  border-right: none;
  height: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #20b8eb;
  border-radius: 3px;
}
.btntogglecontainerfirstnonactive {
  width: 251px;
  background: white;
  outline: none;
  border: none;
  height: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #c6c6c6;
  border-radius: 3px;
}

.article-width {
  width: 250px;
}
.text-center-our-people {
  text-align: center;
  color: #6e6e6e;
  margin: 0;
}
.our-people-img {
  vertical-align: middle;
  width: 80%;
  height: auto;
  border-radius: 50%;
}
.name-styles-stakeholders {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 5px;
  color: #131747;
}
.name-styles-stakeholders-for-svg {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
  color: #131747;
}
.name-styles-stakeholders-last-four {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
  color: #131747;
}
.name-styles-stakeholders-bolded {
  font-weight: bold;
}

.flex-article-ourpeople {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 3.5rem;
  text-align: center;
}

/*MODAL CSS*/
.content-centered-popup {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 2rem;
  padding-bottom: 3rem;
  height: auto;
  border-radius: 50%;
}
.popup-main-text-width {
  width: 80%;
  text-align: left;
  margin-left: auto;
  padding-top: 1.2rem;
  margin-right: auto;
  font-family: Calibri;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.30000001192092896px;
  color: #6e6e6e;
  padding-bottom: 2rem;
}
.popup-title-text {
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  text-align: center;
  color: #20b8eb;
}
.close-btn-position {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 2rem;
}
/* Extra Small */
@media screen and (max-width: 576px) {
  .ourpeoplestakeholdercontainer {
    background: #e6f7ff;
    padding-left: 2.5rem;
    padding-top: 2.5rem;
    padding-right: 2rem;
    padding-bottom: 2.5rem;
    border-radius: 9px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly !important;
            justify-content: space-evenly !important;
    -webkit-align-items: center;
            align-items: center;
    margin: 0px auto;
    max-width: 1250px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 500px;
    max-width: 1250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .name-styles-stakeholders-last-four {
    padding-top: 1.6rem;
  }
  .btntogglecontainerfirstnonactive {
    width: 150px;
  }
  .btntogglecontainerfirstactive {
    width: 150px;
  }
  #btn-management-margin {
    margin-left: 12px !important;
  }
}
/*LARGE SCREEN  AND BELOW*/
@media screen and (max-width: 577px) {
  #btn-management-margin {
    margin-left: 1.5rem;
  }
}

@media screen and (max-width: 1199px) {
  .ourpeoplestakeholdercontainer {
    background: #e6f7ff;
    padding-left: 2.5rem;
    padding-top: 2.5rem;

    padding-bottom: 2.5rem;
    border-radius: 9px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 auto;
    height: 500px;
    max-width: 1250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

/*LARGE SCREEN*/
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1200px) {
  .ourpeoplestakeholdercontainer {
    background: #e6f7ff;
    padding-left: 2.5rem;
    padding-top: 2.5rem;
    padding-right: 2rem;
    border-radius: 9px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 auto;
    max-width: 1250px;
  }
}

.active {
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background-color: #4f88c7;
}
.nonactive {
  height: 15px;
  width: 15px;
  border-radius: 50px;
  background: #d8d8d8;
}

.backg-carousel {
  background: #e6f7ff;
  padding-top: 6rem;
}
.carousel-listener-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 2rem;
}
#circle-event-space {
  margin-right: 0.6rem;
}

.carousel-text {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.2666666805744171px;
  text-align: left;
  color: #6e6e6e;
  padding-top: 4rem;
  padding-right: 1.5rem;
}
.name-title {
  position: absolute;
  bottom: 0;
}
.carousel-name {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.2666666805744171px;
  text-align: left;
  color: #6e6e6e;
  margin: 0;
  padding: 0;
}
.carousel-title-position {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.23333333432674408px;
  text-align: left;
  color: #20b8eb;
  margin-top: 0;
  padding-top: 0;
}
/* Careers Job Component Styling */
.text-center-careers {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0px;
  text-align: center;
}
.paragraghjob {
  text-align: center;
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  text-align: center;
}
.textalljob {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.interested-border {
  border: 1px solid #4f88c7;
}
.switchpanel {
  height: 1049px;
  width: 25%;
  left: 0px;
  top: 784px;
  background-color: #4f88c7;
}

.job-status-click {
  height: 93px;
  width: 100%;
  left: 1px;
  top: 785px;
  color: #4f88c7;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
  padding-left: 1rem;
}
.job-status-click1 {
  height: 93px;
  width: 100%;
  left: 1px;
  top: 785px;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1rem;
  border-bottom: 1.8px solid white;
  margin: 0;
  cursor: pointer;
}
.job-status-click {
  background-color: white;
  height: 93px;
  width: 100%;
  left: 1px;
  top: 785px;
  color: #4f88c7;
  background-color: white;
  cursor: pointer;
}
.hover-text-size {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 24px;
  line-height: 57px;
  letter-spacing: 0px;
  text-align: left;
}
.text-jurisdiction-what-we-need {
  width: 70%;
  margin-left: 5%;
  padding-top: 2%;
}
.text-jurisdiction-what-we-need .what-we-need-paragraph {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
}
.responsibiliity {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
}
.requirement {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
}
.application {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
}
.bk-jobform {
  /* background-color: white; */
  height: 504px;
  width: 100%;
  position: relative;
}
.form-border {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 1px solid #e7e7e7;
  height: 304px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.stay-updated {
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.6000000238418579px;
  width: 66%;
  color: #6e6e6e;
  margin-bottom: 2rem;
}
.btn-signup {
  background: linear-gradient(180deg, #43ddf7 0%, #20b8eb 100%);
  display: block;
  border: none;
  outline: none;
  height: 50px;
  width: 159px;
  left: 694px;
  top: 173px;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.btn-signup:hover {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  background-color: transparent;
}
.btn-signup:active {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  border: 1px solid red;
  color: red;
}
.btn-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.success-message {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: green;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 2px;
  padding: 1rem;
}

::-webkit-input-placeholder {
  padding-left: 0.5rem;
  font-weight: lighter im !important;
}

:-ms-input-placeholder {
  padding-left: 0.5rem;
  font-weight: lighter im !important;
}

::placeholder {
  padding-left: 0.5rem;
  font-weight: lighter im !important;
}

#input-field-signup-boxshadow:focus {
  outline: none !important;
  border-color: #20b8eb;
  box-shadow: 0 0 10px #20b8eb;
}
.input-field-signup {
  max-width: 100%;
  width: 550px;
  border: 1px solid gray;
  outline: none;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding-left: 0.5rem;
  font-size: bold;
  font-weight: bold;
}
/* Media Query Testimonial & Carousel Page */

@media screen and (max-width: 576px) {
  .carousel-text {
    padding-top: 2rem;
    margin-bottom: 6rem;
    padding-left: 1rem;
  }
  .backg-carousel {
    background: #e6f7ff;
    padding-top: 0;
  }
  .name-title {
    padding-left: 1rem;
  }
  .text-container-color {
    background-color: white;
  }
  .stay-updated {
    font-size: 20px !important;
    width: 100%;
  }
}
@media screen and (max-width: 922px) {
  .stay-updated {
    font-size: 26px;
  }
  .input-field-signup {
    max-width: 100%;
    width: 100%;
    border: 1px solid gray;
    outline: none;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding-left: 0.5rem;
    font-size: bold;
    font-weight: bold;
  }
}

@media screen and (min-width: 1200px) {
  .text-jurisdiction-what-we-need .what-we-need-paragraph {
    width: 80%;
  }
}
@media screen and (max-width: 1199px) {
  .text-jurisdiction-what-we-need .what-we-need-paragraph {
    margin-right: 1rem;
  }
}

.CanaryFountionBanner_foundationBanner__170wv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-image: url(/static/media/foundationbanner.aa907c45.png);
  height: 478px;
  color: white;
  font-family: "Gotham Rounded";
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.CanaryFountionBanner_foundationtext__xY44v {
  max-width: 100%;
  width: 757px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Gotham Rounded";
  font-size: 46px;
  font-style: normal;
  font-weight: bolder;
  line-height: 43px;
  letter-spacing: 0px;
  height: auto;
  /* text-align: center; */
}
.CanaryFountionBanner_foundationstatement__1re9H {
  width: 800px;
  max-width: 100%;
  height: 150px;
  background-color: #4f88c7;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.CanaryFountionBanner_foundationcardglobalcontainer__2O6O1 {
  margin: 0 auto;
  width: 80%;
  margin-bottom: 4rem;
}
.CanaryFountionBanner_imgfoundationcontainer__3-wiK {
  width: 40%;
}
.CanaryFountionBanner_foundationbordercontainer__34aFO {
  border: 1.5px solid #4f88c7;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  border-radius: 4px;
  padding: 2rem;
  box-sizing: border-box;
}
.CanaryFountionBanner_foundationcarddescription__2GWRt {
  color: #131747;
  font-family: Gotham Rounded;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
}
.CanaryFountionBanner_foundationcarddescriptionbolded__IM28d {
  font-weight: bold;
}

/* QUERIES */
/* Extra Small */
@media screen and (max-width: 540px) {
  .CanaryFountionBanner_sidetextoffoundationcard__1G1p- {
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: justify !important;
  }
}
@media screen and (max-width: 576px) {
  .CanaryFountionBanner_foundationstatement__1re9H {
    width: 800px;
    max-width: 100%;
    height: 250px;
    background-color: #4f88c7;
    position: absolute;
    top: 50%;
    padding: 1rem;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}
@media screen and (max-width: 800px) {
  .CanaryFountionBanner_foundationcardglobalcontainer__2O6O1 {
    margin: 0 auto;
    width: 100%;
  }
  .CanaryFountionBanner_sidetextoffoundationcard__1G1p- {
    width: 101%;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.3333333432674408px;
    text-align: left;
    color: #6e6e6e;
    padding-top: 1rem;
    text-align: justify !important;
  }
  .CanaryFountionBanner_imgfoundationcontainer__3-wiK {
    width: 101%;
  }
}
@media screen and (min-width: 801px) {
  .CanaryFountionBanner_sidetextoffoundationcard__1G1p- {
    width: 60%;
    padding-left: 2rem;
    font-family: Calibri;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.3333333432674408px;
    text-align: left;
    color: #6e6e6e;
    padding-top: 1rem;
  }
}

.Careers_careersBigText__qSkp9 {
  color: white;
  width: 100%;
  max-width: 721px;
  padding-left: 50px;
  font-family: Calibri;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 59px;
  letter-spacing: 0.800000011920929px;
  text-align: left;
  margin-bottom: 1rem;
}
.Careers_careersSmallText__VZ5H2 {
  color: white;
  padding-left: 50px;
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.6000000238418579px;
  text-align: left;
}
.Careers_careersSmallestTest__201fc {
  color: white;
  padding-left: 50px;
  font-family: Calibri;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}
/* Culture Core Value styling... */
.Careers_coreValueBk__1Ddrp {
  width: 100%;
  height: 490px;
  background-color: #e6f7ff;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Careers_corevaluetext__On-gC {
  font-size: large;
  font-size: 28px;
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #131747;
}
.Careers_corevaluetext__On-gC span {
  font-weight: bold;
  color: #131747;
}
.Careers_corestatement__ZD8Ds {
  margin-top: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
  color: #6e6e6e;
}
/*JOIN OUR TEAM COMPONENT STYLES*/
.Careers_jointeam__s3XdT {
  background-color: white;
  width: 100%;
}
.Careers_wrapperall__1dXgB {
  background-color: white;
}
.Careers_jointeam__s3XdT h1 {
  margin-bottom: 0;
  margin-top: 0;
}
.Careers_jointeam__s3XdT p {
  margin-top: 0;
  margin-bottom: 0;
}
.Careers_jointeamcontainer__3kFcw {
  text-align: center;
  padding-top: 76px;
  margin-bottom: 2.5rem;
}
.Careers_flexcardcontainer__3HGJu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 5rem;
  margin-bottom: 4rem;
}
.Careers_cardourteam__3W_qL {
  height: 263px;
  width: 307px;
  left: 179px;
  margin-bottom: 1rem;
  top: 1807px;
  border-radius: 8px;
  border: 1.5px solid #c2ebfe;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-flow: column;
          flex-flow: column;
}
.Careers_boldpartoftext__1yK2R {
  font-weight: bold;
}
.Careers_textdifferent__16zrz {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: #e06c26;
  margin-bottom: 0;
}
.Careers_achievers__3-YeD {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: 29px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  color: #6e6e6e;
}
.Careers_joinourteam__TAcug {
  font-size: large;
  font-size: 28px;
  margin-bottom: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #131747;
}
.Careers_joinourteam__TAcug span {
  font-weight: bold;
}
.Careers_availablebtn__2bsJu {
  height: 50px;
  width: 262px;
  left: 589px;
  top: 2484px;
  border-radius: 25px;
  background: linear-gradient(180deg, #43ddf7 0%, #20b8eb 100%);
  border: none;
  font-size: bold;
  color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;

  cursor: pointer;
}
.Careers_availablebtn__2bsJu:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  background-color: transparent;
}
.Careers_availablebtn__2bsJu:active {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  border: 1px solid red;
  color: red;
}
/*CANARY COLONY COMPONENT STYLES*/
.Careers_colonycontainer__3KvYz {
  width: 100%;
  height: 576px;
  max-width: 1280px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: content-box;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.Careers_bkgcolony__6lvKp {
  background-color: white;
}
.Careers_flexcolonyitem1__X1du- {
  width: 50%;
}
.Careers_flexcolonyitem__2Dnqd {
  width: 50%;
}
.Careers_avatar__2f8uf {
  vertical-align: middle;
  width: 50%;
  height: 50%px;
  border-radius: 50%;
}
.Careers_avatar2__2yoDv {
  vertical-align: middle;
  width: 50%;
  height: auto;
  border-radius: 50%;
  border: 10px solid white;
  position: relative;
  top: -6rem;
  left: 8.2rem;
}
.Careers_colonytext__3jemj {
  width: 450px;
  height: 116px;
  text-justify: inter-word;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 1rem;
  color: #6e6e6e;
}
.Careers_joinusbtn__2nF2V {
  height: 50px;
  width: 199px;
  left: 748px;
  top: 930px;
  border-radius: 25px;
  background: linear-gradient(180deg, #43ddf7 0%, #20b8eb 100%);
  color: white;
  font-size: bold;
  outline: none;
  border: none;
}

.Careers_joinusbtn__2nF2V:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  background-color: transparent;
}
.Careers_joinusbtn__2nF2V:active {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  border: 1px solid red;
  color: red;
}
/* Carousel Styling Section */
.Careers_flexcontainercarousel__3aCw9 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 1280px;
  width: 100%;
}

.Careers_carousel__139cl {
  width: 55%;
}
.Careers_carouseltextarea__3xaiv {
  width: 45%;
  position: relative;
  height: 429px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Careers_imgpaddingright__13Cc7 {
  padding-right: 2rem;
}
.Careers_textareaitems__6cdPp {
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.Careers_carouselbtn__2A5vf {
  background: linear-gradient(180deg, #43ddf7 0%, #20b8eb 100%);
  height: 50px;
  width: 199px;
  left: 133px;
  top: 2959px;
  border-radius: 25px;
  border: none;
  outline: none;
  font-family: Calibri;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
}
.Careers_carouselbtn__2A5vf:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  background-color: transparent;
}
.Careers_carouselbtn__2A5vf:active {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  border: 1px solid red;
  color: red;
}
.Careers_carouselinnertext__1r5oh {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.3333333432674408px;
  text-align: left;
  color: #6e6e6e;
  height: 58px;
  width: 89%;
  margin-top: 0;
  margin-bottom: 2rem;
}
/* Careers Job Component Styling  */
.Careers_backgroundjobbanner__3-bJT {
  background-image: url(/static/media/jobs.213c8d48.png);
  height: 478px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}
.Careers_interestedbkground__1UXcl {
  height: 460px;
  background-color: white;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.Careers_interestedinnertext__17DzJ {
  color: #131747;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 28px;
  line-height: 29px;
  letter-spacing: 0.46666666865348816px;
  text-align: center;
  margin-bottom: 1.8rem;
}
.Careers_careerjobswitch__28K__ {
  position: absolute;
  top: 50px;
}
/* QUERIES */
/* Extra Small */
@media screen and (max-width: 576px) {
  .Careers_careersBigText__qSkp9 {
    font-size: 32px;
  }
  .Careers_carouselflexitem__2lTpT {
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: auto;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Careers_imgpaddingright__13Cc7 {
    padding-right: 0;
  }
  .Careers_cardWhiteDesign__2xHVt {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .Careers_cardWhiteDesignContainer__1k_Ey {
    max-width: 600px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 auto;
    overflow-x: scroll;
    overflow: -moz-hidden-unscrollable;
  }
  .Careers_achievers__3-YeD {
    font-size: 15px;
  }
  .Careers_picturesdown__2SB5k {
    height: auto !important ;
    margin-top: 2rem;
  }
}
/* Small  */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .Careers_cardWhiteDesign__2xHVt {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
  }
  .Careers_cardWhiteDesignContainer__1k_Ey {
    max-width: 600px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: scroll;
    overflow: hidden;
    cursor: pointer;
  }
}
/*Other Screen adjustment For Perfect Layout*/
@media screen and (max-width: 638px) {
  .Careers_bkgcolony__6lvKp {
    background-color: white;
    height: 800px;
  }
}
@media screen and (max-width: 441px) {
  .Careers_bkgcolony__6lvKp {
    background-color: white;
    height: 760px;
  }
}
@media screen and (max-width: 990px) {
  .Careers_flexcolonyitem1__X1du- {
    width: 101%;
  }
  .Careers_flexcolonyitem__2Dnqd {
    width: 101%;
  }
}
@media screen and (max-width: 915px) {
  .Careers_colonytext__3jemj {
    width: 100%;
  }
  .Careers_colonytext__3jemj {
    height: auto;
  }
}
@media screen and (max-width: 563px) {
  .Careers_colonycontainer__3KvYz {
    height: 900px !important;
  }
}
@media screen and (max-width: 638px) {
  .Careers_colonycontainer__3KvYz {
    height: 638px !important;
  }
}
@media screen and (max-width: 767px) {
  .Careers_colonycontainer__3KvYz {
    height: 1049px;
  }
  .Careers_picturesdown__2SB5k {
    height: 486px;
    margin-top: 2rem;
  }
  .Careers_colonytext__3jemj {
    height: auto;
  }
}
/* MEDIUM  */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .Careers_cardWhiteDesign__2xHVt {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
    cursor: pointer;
  }
  .Careers_cardWhiteDesignContainer__1k_Ey {
    max-width: 600px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: scroll;
    overflow: hidden;
  }
  .Careers_colonycontainer__3KvYz {
    height: 1010px;
  }
  .Careers_picturesdown__2SB5k {
    height: 700px;
    margin-top: 2rem;
  }
  .Careers_carousel__139cl {
    width: 101%;
  }
  .Careers_carouseltextarea__3xaiv {
    width: 101%;
    position: relative;
  }
}
/* LARGE SCREEN  */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .Careers_cardWhiteDesign__2xHVt {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
    cursor: pointer;
  }
  .Careers_cardWhiteDesignContainer__1k_Ey {
    max-width: 600px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: scroll;
    scroll-behavior: smooth;
    overflow: hidden;
  }
}
@media screen and (min-width: 1200px) {
  .Careers_cardWhiteDesign__2xHVt {
    background-color: white;
    width: 161px;
    height: 45px;
    margin-top: 30px;
    margin-right: 30px;
    color: #20b8eb;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
    cursor: pointer;
  }
  .Careers_cardWhiteDesignContainer__1k_Ey {
    max-width: 600px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: scroll;
    overflow: hidden;
  }
  .Careers_picturesdown__2SB5k {
    margin-top: 20%;
  }
}
/* Media Queries Padding for Carousel display */
@media screen and (max-width: 992px) {
  .Careers_carousel__139cl {
    width: 101%;
  }
  .Careers_carouseltextarea__3xaiv {
    width: 101%;
    position: relative;
  }
  .Careers_carouselinnertext__1r5oh {
    height: 58px;
    width: 100%;
  }
  .Careers_carouseltextarea__3xaiv {
    height: 243px;
  }
}
@media screen and (min-width: 577px) {
  .Careers_carouselflexitem__2lTpT {
    display: -webkit-flex;
    display: flex;
    position: relative;
    background-color: white;
  }
}

@font-face {
  font-family: "Gotham";
  src: url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.eot");
  src: url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.svg#Gotham")
      format("svg");
}
.Navbar_label__2vojo {
  text-transform: none;
  text-transform: initial;
  font-size: 1rem;
  font-family: "Gotham Rounded" !important;
  font-style: normal;
  text-transform: initial;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
}

.Navbar_label__2vojo:hover {
  color: #4f88c7;
}
.Navbar_label__2vojo:visited {
  color: #4f88c7;
}
.Navbar_fontpaddingfornavbar__B9kfl {
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Gotham Rounded";
  font-weight: normal;
  line-height: 19px;
  color: #6e6e6e;
  font-style: normal;
  padding-right: 40px;
  cursor: pointer;
}
.Navbar_fontpaddingfornavbar__B9kfl:active,
.Navbar_fontpaddingfornavbar__B9kfl:link,
.Navbar_fontpaddingfornavbar__B9kfl:visited,
.Navbar_fontpaddingfornavbar__B9kfl:hover,
.Navbar_fontpaddingfornavbar__B9kfl:focus {
  color: #4f88c7 !important;
}
@media screen and (max-width: 1200px) {
  .Navbar_fontpaddingfornavbar__B9kfl {
    padding-right: 25px;
    font-size: 15px;
  }
}

html {
  line-height: 1;
}
html,
body {
  padding: 0;
  margin: 0;
  position: relative;
}

html,
body,
#root,
.App,
.fullHeight {
  height: 100%;
}

a {
  text-decoration: none;
  list-style: none;
}
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1;
}

#clearfix {
  overflow: auto;
}

.get-started-btn {
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  text-shadow: 1px 1px 0px #7cacde;
  box-shadow: 1px 1px 1px #bee2f9;
  padding: 10px 25px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background: linear-gradient(180deg, #43ddf7 0%, #20b8eb 100%);
}
.get-started-btn:hover {
  color: #ffffff !important;
  background: #468ccf;
  background: linear-gradient(to top, #468ccf, #63b8ee);
}

